import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { ApiService } from '@fyle/api';
import { TokenService } from '@fyle/token';
import { OrgStorageService } from '@fyle/org-storage';
import { DataTransformService } from '@fyle/data-transformer';
import { ExtendedOrgUser, ExtendedOrgUserResponse, UserRole, AuthPayload, AuthResponse } from '@fyle/model-shared';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private orgStorageService: OrgStorageService,
    private dataTransformService: DataTransformService,
  ) {}

  clearBasicOrgStorage(): void {
    this.orgStorageService.remove('user');
    this.orgStorageService.remove('in_app_chat_restore_id');
  }

  refreshEou(): Observable<ExtendedOrgUser> {
    return this.apiService.get<ExtendedOrgUserResponse>('/eous/current').pipe(
      map((data) => {
        const extendedOrgUser: ExtendedOrgUser = this.dataTransformService.unflatten<ExtendedOrgUser>(data);
        this.orgStorageService.set('user', extendedOrgUser);
        return extendedOrgUser;
      }),
    );
  }

  getNewAccessToken(refreshToken: string): Observable<string> {
    const accessToken = this.tokenService.getAccessToken();
    this.clearBasicOrgStorage();
    this.tokenService.resetAccessToken();
    this.tokenService.setRefreshToken(refreshToken);

    const params: AuthPayload = {
      refresh_token: refreshToken,
      access_token: accessToken,
    };

    return this.apiService.post<AuthResponse>('/auth/access_token', params).pipe(
      switchMap((res) => {
        this.tokenService.setAccessToken(res.access_token);
        return this.refreshEou();
      }),
      map(() => this.tokenService.getAccessToken()),
    );
  }

  getEou(): ExtendedOrgUser {
    return this.orgStorageService.get('user');
  }

  getRoles(): UserRole[] {
    const currentUser = this.getEou();
    return currentUser.ou.roles;
  }

  isNewOptInFlowEnabled(): boolean {
    const eou = this.getEou();
    const isUserFromINCluster = this.tokenService.isUserFromINCluster();

    return ['USD', 'CAD'].includes(eou.org.currency) && !isUserFromINCluster;
  }
}
