import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSize } from '@fyle/model-shared';

@Component({
  selector: 'ui-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-modal.component.html',
  styleUrls: ['./ui-modal.component.scss'],
})
export class FdlModalComponent {
  @Input() size: ModalSize = ModalSize.SMALL;

  @Input() noMinHeight: boolean = false;
}
