/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import extend from 'lodash-es/extend';
import { AuthService } from '@fyle/auth';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    private authService: AuthService,
    private window: Window,
  ) {}

  private getUserProperties(): Record<string, string> {
    const properties: Record<string, string> = {};
    const eou = this.authService.getEou();
    // Replace any with proper type
    if (eou?.ou) {
      properties['User Email'] = eou.us.email;
      properties['User Org Name'] = eou.ou.org_name;
      properties['User Org ID'] = eou.ou.org_id;
      properties['User Full Name'] = eou.us.full_name;
      properties['User Org Currency'] = eou.org.currency;
    }
    return properties;
  }

  eventTrack(action: string, properties: Record<string, any> = {}): void {
    properties.Asset = 'webapp';
    extend(properties, this.getUserProperties());
    try {
      if ((this.window as any).mixpanel) {
        (this.window as any).mixpanel.track(action, properties);
      }
    } catch (e) {}
  }
}
