import { Injectable, Type } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FdlDialogConfig, ConfirmationModalConfig } from '@fyle/model-shared';
import { ConfirmationModalComponent } from '@fyle/confirmation-modal';
import { IndeterminateLoaderComponent } from '@fyle/indeterminate-loader';
import { AnimationEvent } from '@angular/animations';
@Injectable({
  providedIn: 'root',
})
export class FdlDialogService {
  defaultDialogConfiguration: FdlDialogConfig = {
    showHeader: false,
    contentStyle: { padding: 0 },
  };

  constructor(private dialogService: DialogService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open(dynamicComponent: Type<any>, dialogConfig?: FdlDialogConfig, focusOnClose?: boolean): DynamicDialogRef {
    const ref = this.dialogService.open(dynamicComponent, {
      ...this.defaultDialogConfiguration,
      ...dialogConfig,
    });

    // Only apply to the dialog which passes focusOnClose property as false, else show normal behaviour
    if (focusOnClose === false) {
      /*
       * Hack: PrimeNG introduced this fix in version 17.18.0, but since we are using an earlier version,
       * we are manually overriding the onAnimationEnd method in the PrimeNG dialog to prevent
       * the parent dialog from scrolling to the top when it is closed.
       * This workaround ensures that the parent dialog remains in its current position upon closing the child dialog.
       * Once we upgrade to PrimeNG 17.18.0 or higher, we should remove this hack.
       * ref: https://github.com/primefaces/primeng/pull/16336
       */
      const dynamicDialogComponent = this.dialogService.getInstance(ref);
      dynamicDialogComponent.onAnimationEnd = (event: AnimationEvent): void => {
        if (event.toState === 'void') {
          dynamicDialogComponent.onContainerDestroy();
          ref.destroy();
        }
      };
    }

    return ref;
  }

  openConfirmationModal(modalConfig: ConfirmationModalConfig, focusOnClose?: boolean): DynamicDialogRef {
    return this.open(ConfirmationModalComponent, { data: modalConfig }, focusOnClose);
  }

  openIndeterminateLoaderModal(loaderText: string): DynamicDialogRef {
    return this.open(IndeterminateLoaderComponent, { data: loaderText });
  }
}
