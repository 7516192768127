// TODO: Aniruddha to fix the types here for get and set methods
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  constructor(private localStorageService: LocalStorageService) {}

  get<T>(key: string): T {
    return this.localStorageService.get(key);
  }

  set<T>(key: string, value: T): void {
    this.localStorageService.set(key, value);
  }

  remove(key: string): void {
    this.localStorageService.remove(key);
  }

  removeAll(): void {
    this.localStorageService.clear();
  }
}
