<ui-modal [size]="modalSize.SMALL">
    <ui-modal-header
      [type]="modalConfiguration.header.type"
      [title]="modalConfiguration.header.title"
      (closeModal)="close()"
      >
    </ui-modal-header>
  
    <ui-modal-content class="tw-grow tw-p-spacing-24" data-testId="confirmation-modal-content">
      @if (customTemplate) {
        <div>
          <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
        </div>
      } @else {
        <div [innerHTML]="modalConfiguration.content"></div>
      }
      @if (modalConfiguration?.showConfirmationMessage) {
        <div class="tw-mt-spacing-16" [innerHTML]="modalConfiguration.confirmationMessage  ? modalConfiguration.confirmationMessage : defaultConfirmationMessage"></div>
      }
    </ui-modal-content>
    <ui-modal-actions
      [primaryAction]="modalConfiguration.actions.primaryAction"
      [secondaryAction]="modalConfiguration.actions.secondaryAction"
      >
    </ui-modal-actions>
</ui-modal>
  