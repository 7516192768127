import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataTransformService {
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unflatten<T>(data: Record<string, any>): T {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: Record<string, any> = {};
    const keys = Object.keys(data);

    for (const key of keys) {
      const idx = key.indexOf('_');
      if (idx > -1) {
        const member = key.slice(0, idx);
        const strippedKey = key.slice(idx + 1, key.length);
        if (!res[member]) {
          res[member] = {};
        }
        res[member][strippedKey] = data[key];
      } else {
        res[key] = data[key];
      }
    }
    return res as T;
  }
}
