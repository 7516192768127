import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgSpriteComponent } from '@fyle/svg-sprite';
import { ModalHeaderTypes } from '@fyle/model-shared';

@Component({
  selector: 'ui-modal-header',
  standalone: true,
  imports: [CommonModule, SvgSpriteComponent],
  templateUrl: './ui-modal-header.component.html',
  styleUrls: ['./ui-modal-header.component.scss'],
})
export class FdlModalHeaderComponent {
  @Input() type: ModalHeaderTypes = ModalHeaderTypes.NORMAL;

  @Input() title: string = '';

  @Input() showCloseButton: boolean = true;

  @Output() closeModal = new EventEmitter<void>();

  modalHeaderTypes = ModalHeaderTypes;

  close(): void {
    this.closeModal.emit();
  }
}
