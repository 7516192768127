import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'ui-indeterminate-loader',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './ui-indeterminate-loader.component.html',
  styleUrls: ['./ui-indeterminate-loader.component.scss'],
})
export class IndeterminateLoaderComponent implements OnInit {
  loadingText = '';

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.loadingText = this.dialogConfig.data;
  }
}
