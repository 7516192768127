//TODO: To be remove when migration is complete
/* eslint-disable project-structure/folder-structure */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  imports: [CommonModule],
  providers: [DialogService],
})
export class FdlDialogModule {}
