import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GlobalCacheConfig } from 'ts-cacheable';
import { AppModule } from './app/app.module';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';
import { ExtendedOrgUser } from './app/core/models/extended-org-user.model';

GlobalCacheConfig.maxAge = 10 * 60 * 1000;

function setSentryUserContext(event: Sentry.Event) {
  const orgDataStr = localStorage.getItem('fyle.org_data');
  if (orgDataStr) {
    const orgData = JSON.parse(orgDataStr);
    const orgIds = Object.keys(orgData);
    if (orgIds?.length > 0) {
      const currentOrgId = orgIds[0];
      const eou = orgData[currentOrgId] && (orgData[currentOrgId].user as ExtendedOrgUser);
      if (eou) {
        event.user.id = eou.us.email + ' - ' + eou.ou.id;
        event.user.email = eou.us.email;
        event.user.orgUserId = eou.ou.id;
      }
    }
  } else {
    /* `fyle.user` is fallback key name, in case the user's local storage is not migrated. */
    const eouStr = localStorage.getItem('fyle.user');
    if (eouStr) {
      const eou = JSON.parse(eouStr) as ExtendedOrgUser;
      event.user.id = eou.us.email + ' - ' + eou.ou.id;
      event.user.email = eou.us.email;
      event.user.orgUserId = eou.ou.id;
    }
  }
}

// To modify the exception values for Http errors to remove query params from the URL so that grouping is done properly in Sentry
const cleanHttpExceptionUrlsForSentryGrouping = (event: Sentry.Event): void => {
  const prefix = 'Http failure response for ';
  const suffixIndicator = ': ';

  if (event.exception?.values?.[0].value?.startsWith(prefix)) {
    // exceptionValue looks like: 'Http failure response for https://staging.fyle.tech/platform/v1/common/currency/exchange_rate?from=null&to=USD&date=2024-10-01: 400 OK'
    const exceptionValue = event.exception?.values?.[0].value;

    // Finding the position of the last occurrence of ': '
    const suffixIndex = exceptionValue.lastIndexOf(suffixIndicator);

    if (suffixIndex !== -1) {
      const urlWithQuery = exceptionValue.slice(prefix.length, suffixIndex);
      const urlWithoutQuery = urlWithQuery.split('?')[0];
      const suffix = exceptionValue.slice(suffixIndex + suffixIndicator.length);

      // Updating the exception message
      event.exception.values[0].value = `${prefix}${urlWithoutQuery}${suffixIndicator}${suffix}`;
    }
  }
};

if (environment.SEND_ERROR_TO_SENTRY) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    sampleRate: environment.SENTRY_TRACES_SAMPLE_RATE,
    ignoreErrors: [
      'Non-Error promise rejection captured', // MS Outlook safelink checker errors
      'Failed to construct', // MouseEvent errors on using webapp on mobile
      'transition superseded',
      'transition prevented', // Expected error when state transition is prevented if user is not logged in
      'Refresh token not found', // Expected error when refresh token is not found
      'Session has expired or is invalid', // Expected error when user logout due to inactivity
      /Possibly unhandled rejection: undefined/, // No code trace/ api call failures, so can’t debug
      /Possibly unhandled rejection: {.*"status":-1.*}/, // Network related issues, network calls fails with 0 status code in breadcrumbs
      /angular is not defined/, // Injection related issues in AngularJS app
      /Failed to fetch/, // Issue seems to be in sentry unable to load few things
      /0 Unknown Error/, // Network related issues - 0 unknown error
      /PDFJS is not defined/, // Issue in pdfJS lib from angular JS
      /Possibly unhandled rejection: not-element/, // This issue occasionally arises when opening an AngularJS Material dialog. Despite using a catch block to handle rejections we still face this in prod, it has no impact on functionality.
    ],
    environment: environment.ENVIRONMENT,
    beforeSend(event) {
      cleanHttpExceptionUrlsForSentryGrouping(event);
      setSentryUserContext(event);
      return event;
    },
    release: environment.RELEASE,
  });

  /* adding null scope here so that I am able to edit it in before sending event to sentry
   * This is added because configureScope will not work in beforeSend of sentry
   */
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: null,
      email: null,
      orgUserId: null,
    });
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
