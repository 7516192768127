import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TabStorageService {
  constructor(private window: Window) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  set(key: string, value: any): void {
    this.window.sessionStorage.setItem(`fyle.${key}`, value);
  }

  get(key: string): string | null {
    return this.window.sessionStorage.getItem(`fyle.${key}`);
  }

  remove(key: string): void {
    this.window.sessionStorage.removeItem(`fyle.${key}`);
  }

  removeAll(): void {
    this.window.sessionStorage.clear();
  }
}
