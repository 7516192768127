import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, tap, throwError } from 'rxjs';
import { RouterAuthService } from '../../core/services/router-auth.service';
import { PasswordRulesValidator } from '../../shared/components/password-rules/password-rules-validator';
import { ComparePassword } from './compare-password-validator';
import { NewPasswordQueryParams } from './new-password-query-params.interface';
import { TargetAppConfigService } from '@fyle/target-app-config';
import { ButtonType, TargetAppConfig } from '@fyle/model-shared';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  showPlainPassword: boolean;

  showPlainConfirmPassword: boolean;

  showLoaderOnButton: boolean;

  isResetPasswordFailed: boolean;

  passwordForm: FormGroup;

  buttonType = ButtonType;

  private queryParams: NewPasswordQueryParams;

  targetConfig: TargetAppConfig;

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerAuthService: RouterAuthService,
    private targetAppConfigService: TargetAppConfigService,
  ) {
    this.passwordForm = formBuilder.group(
      {
        newPassword: ['', [Validators.required, PasswordRulesValidator.checkPasswordStrength]],
        confirmPassword: ['', Validators.required],
      },
      { validators: ComparePassword.mustMatch },
    );
  }

  goToSignIn() {
    this.router.navigate(['signin']);
  }

  goToResetPassword() {
    this.router.navigate(['reset_password']);
  }

  savePassword() {
    if (this.passwordForm.invalid) {
      return;
    }

    this.showLoaderOnButton = true;
    this.isResetPasswordFailed = false;

    const password = this.passwordForm.controls.newPassword.value;
    this.routerAuthService
      .resetPassword(this.queryParams.refresh_token, password)
      .pipe(
        tap(() => {
          this.router.navigate(['verify']);
        }),
        catchError((error) => {
          this.isResetPasswordFailed = true;
          return throwError(() => error);
        }),
        finalize(() => {
          this.showLoaderOnButton = false;
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.targetConfig = this.targetAppConfigService.getTargetConfig();
    this.queryParams = this.activatedRoute.snapshot.queryParams as NewPasswordQueryParams;
  }
}
